<template>
    <div>
        <app-page-header></app-page-header>
        <v-container grid-list-xl>
            <v-form id="form_rfid_user" ref="form" v-model="valid" lazy-validation>
                <v-layout row wrap align-center justify-center fill-height>
                    <v-flex xs12 sm12 md10 lg10 xl8>
                        <v-card id="form_card">
                            <v-card-title>
                                <v-icon left>mdi-account</v-icon>
                                    <span class="subtitle-1 font-weight-regular application--card--title">Osebni podatki</span>
                                    <v-spacer></v-spacer>
                                <v-btn icon @click="toggleCardContent('user-data')">
                                    <v-icon>{{ card1 ? 'mdi-chevron-down' : 'mdi-chevron-up' }}</v-icon>
                                </v-btn>
                            </v-card-title>
                            <v-divider v-show="card1"></v-divider>

                            <!-- osebni podatki -->
                            <v-card-text v-show="card1">
                                <div style="min-height:10px"></div>
                                    <p class="body-2 font-weight-regular card--text"><v-icon class="p--icon" medium color="secondary" left>mdi-information</v-icon> Urejate lahko le polja, ki so označena z zvezdico (*). </p>
                                <div style="min-height:10px"></div>

                                <!-- backend validation messages -->
                                <form-validation-messages :messages="apiValidationMessages"></form-validation-messages>
                                <form-submit-messages :messages="apiMessages" :errorMessages="apiErrorMessages"></form-submit-messages>


                                <v-text-field
                                    v-model="name"
                                    label="Ime in priimek"
                                    required
                                    :rules="name_rules"
                                    readonly
                                    filled
                                ></v-text-field>
                                <v-text-field
                                    v-model="address"
                                    label="Ulica (stalno prebivališče)"
                                    readonly
                                    filled
                                ></v-text-field>
                                <v-text-field
                                    v-model="city"
                                    label="Kraj (stalno prebivališče)"
                                    readonly
                                    filled
                                ></v-text-field>
                                <!--<v-text-field
                                    v-model="phone_number"
                                    label="Telefonska številka"
                                    clearable
                                ></v-text-field>-->
                                <v-text-field
                                    v-model="email"
                                    label="E-mail"
                                    required
                                    readonly
                                    filled
                                ></v-text-field>
                            </v-card-text>

                            <!-- telefonske številke -->
                            <v-divider></v-divider>
                            <v-app-bar
                                flat
                                color="grey lighten-4"
                                dense
                                class="elevation-0"
                            >   
                                <v-icon left>mdi-cellphone-android</v-icon>
                                <v-toolbar-title class="body-2">Telefonske številke</v-toolbar-title>
                                <v-spacer></v-spacer>
                                <v-btn icon @click="toggleCardContent('phone-numbers')">
                                    <v-icon>{{ card2 ? 'mdi-chevron-down' : 'mdi-chevron-up' }}</v-icon>
                                </v-btn>

                            </v-app-bar>
                            <v-divider></v-divider>
                            <v-card-text v-show="card2">
                                <!-- telefonske številke -->
                                <v-container grid-list-xl text-x-center align-center>
                                    <v-layout row justify-center v-bind="columnBinding">
                                        <v-flex x12 align-content-center>
                                            <p>Telefonske številke:</p>
                                        </v-flex>
                                    </v-layout>
                                    <v-row>
                                        <v-col cols="12" xs="12" :sm="phoneNumbersColSizes" :md="phoneNumbersColSizes" :lg="phoneNumbersColSizes">
                                            <v-text-field
                                                v-model="phone_number_1"
                                                label="Telefonska številka 1 *"
                                                clearable
                                            ></v-text-field>
                                        </v-col>
                                        <v-col cols="12" xs="12" :sm="phoneNumbersColSizes" :md="phoneNumbersColSizes" :lg="phoneNumbersColSizes" v-if="(phone_numbers_limit > 1) ? true : false">
                                            <v-text-field
                                                v-model="phone_number_2"
                                                label="Telefonska številka 2 *"
                                                clearable
                                            ></v-text-field>
                                        </v-col>
                                        <v-col cols="12" xs="12" :sm="phoneNumbersColSizes" :md="phoneNumbersColSizes" :lg="phoneNumbersColSizes" v-if="(phone_numbers_limit > 2)">
                                            <v-text-field
                                                v-model="phone_number_3"
                                                label="Telefonska številka 3 *"
                                                clearable
                                            ></v-text-field>
                                        </v-col>
                                        <v-col cols="12" xs="12" :sm="phoneNumbersColSizes" :md="phoneNumbersColSizes" :lg="phoneNumbersColSizes" v-if="(phone_numbers_limit > 3)">
                                            <v-text-field
                                                v-model="phone_number_4"
                                                label="Telefonska številka 4 *"
                                                clearable
                                            ></v-text-field>
                                        </v-col>
                                        <v-col cols="12" xs="12" :sm="phoneNumbersColSizes" :md="phoneNumbersColSizes" :lg="phoneNumbersColSizes" v-if="(phone_numbers_limit > 4)">
                                            <v-text-field
                                                v-model="phone_number_5"
                                                label="Telefonska številka 5 *"
                                                clearable
                                            ></v-text-field>
                                        </v-col>
                                    </v-row>
                                    <v-layout row justify-center v-bind="columnBinding">
                                        <v-flex x12 align-content-center>
                                            <p class="body-1">Dovoli vstop z uporabo telefonskih številk: {{ userPhoneNumbersEnabled }}</p>
                                        </v-flex>
                                    </v-layout>
                                </v-container>
                            </v-card-text>

                            <!-- registrske številke -->
                            <v-divider></v-divider>
                            <v-app-bar
                                flat
                                color="grey lighten-4"
                                dense
                                class="elevation-0"
                            >   
                                <v-icon left>mdi-car</v-icon>
                                <v-toolbar-title class="body-2">Registrske številke</v-toolbar-title>
                                <v-spacer></v-spacer>
                                <v-btn icon @click="toggleCardContent('registration-numbers')">
                                    <v-icon>{{ card9 ? 'mdi-chevron-down' : 'mdi-chevron-up' }}</v-icon>
                                </v-btn>

                            </v-app-bar>
                            <v-divider></v-divider>
                            <v-card-text v-show="card9">
                                <!-- registrske številke -->
                                <v-container grid-list-xl text-x-center align-center>
                                    <v-layout row justify-center v-bind="columnBinding">
                                        <v-flex x12 align-content-center>
                                            <p>Registrske številke:</p>
                                        </v-flex>
                                    </v-layout>
                                    <v-row>
                                        <v-col cols="12" xs="12" :sm="registrationNumbersColSizes" :md="registrationNumbersColSizes" :lg="registrationNumbersColSizes">
                                            <v-text-field
                                                v-model="registration_number_1"
                                                label="Registrska številka 1 *"
                                                clearable
                                            ></v-text-field>
                                        </v-col>
                                        <v-col cols="12" xs="12" :sm="registrationNumbersColSizes" :md="registrationNumbersColSizes" :lg="registrationNumbersColSizes" v-if="(registration_numbers_limit > 1)">
                                            <v-text-field
                                                v-model="registration_number_2"
                                                label="Registrska številka 2 *"
                                                clearable
                                            ></v-text-field>
                                        </v-col>
                                        <v-col cols="12" xs="12" :sm="registrationNumbersColSizes" :md="registrationNumbersColSizes" :lg="registrationNumbersColSizes" v-if="(registration_numbers_limit > 2) ">
                                            <v-text-field
                                                v-model="registration_number_3"
                                                label="Registrska številka 3 *"
                                                clearable
                                            ></v-text-field>
                                        </v-col>
                                        <v-col cols="12" xs="12" :sm="registrationNumbersColSizes" :md="registrationNumbersColSizes" :lg="registrationNumbersColSizes" v-if="(registration_numbers_limit > 3)">
                                            <v-text-field
                                                v-model="registration_number_4"
                                                label="Registrska številka 4 *"
                                                clearable
                                            ></v-text-field>
                                        </v-col>
                                        <v-col cols="12" xs="12" :sm="registrationNumbersColSizes" :md="registrationNumbersColSizes" :lg="registrationNumbersColSizes" v-if="(registration_numbers_limit > 4)">
                                            <v-text-field
                                                v-model="registration_number_5"
                                                label="Registrska številka 5 *"
                                                clearable
                                            ></v-text-field>
                                        </v-col>
                                    </v-row>
                                    <v-layout row justify-center v-bind="columnBinding">
                                        <v-flex x12 align-content-center>
                                            <p class="body-1">Dovoli vstop z uporabo registerskih številk: {{ userRegistrationNumbersEnabled }}</p>
                                        </v-flex>
                                    </v-layout>
                                </v-container>
                            </v-card-text>

                            <!-- rfid kartica
                            <v-divider></v-divider>
                            <v-app-bar
                                flat
                                color="grey lighten-4"
                                dense
                                class="elevation-0"
                            >   
                                <v-icon left>mdi-file-document-outline</v-icon>
                                <v-toolbar-title class="body-2">RFID kartica</v-toolbar-title>
                                <v-spacer></v-spacer>
                                <v-btn icon @click="toggleCardContent('rfid-card')">
                                    <v-icon>{{ card6 ? 'mdi-chevron-down' : 'mdi-chevron-up' }}</v-icon>
                                </v-btn>

                            </v-app-bar>
                            <v-divider></v-divider>
                            <v-card-text v-show="card6">
                                <v-container grid-list-xl text-x-center align-center>
                                    
                                    <v-layout row justify-center v-bind="columnBinding">
                                        <v-flex xs12 md4 align-content-center>
                                            <v-subheader class="pt-7 pl-0"><strong>ID kartice</strong></v-subheader>
                                        </v-flex>
                                        <v-flex xs12 md8 align-content-center>
                                            <v-text-field
                                                v-model="card_id"
                                                label="ID kartice"
                                                ref="cardId"
                                                readonly
                                                filled
                                            ></v-text-field>
                                        </v-flex>
                                    </v-layout>

                                    
                                    <v-layout row justify-center v-bind="columnBinding">
                                        <v-flex xs12 md4 align-content-center>
                                            <v-subheader class="pt-7 pl-0"><strong>Serijska številka kartice</strong></v-subheader>
                                        </v-flex>
                                        <v-flex xs12 md8 align-content-center>
                                            <v-text-field
                                                v-model="card_number"
                                                label="Serijska št. kartice"
                                                ref="cardNumber"
                                                readonly
                                                filled
                                            ></v-text-field>
                                        </v-flex>
                                    </v-layout>

                                    
                                    <v-layout row justify-center v-bind="columnBinding">
                                        <v-flex xs12 md4 align-content-left v-if="$vuetify.breakpoint.lgAndUp">
                                            <v-subheader><strong></strong></v-subheader>
                                        </v-flex>
                                        <v-flex x12 align-content-center>
                                            <p class="body-1">Dovoli vstop z uporabo kartice: {{ cardBlocked }}</p>
                                        </v-flex>
                                    </v-layout>
                                </v-container>
                            </v-card-text>
                             -->

                            
                            <!-- stanje uporabnika -->
                            <!--<v-divider></v-divider>
                            <v-app-bar
                                flat
                                color="grey lighten-4"
                                dense
                                class="elevation-0"
                            >   
                                <v-icon left>mdi-state-machine</v-icon>
                                <v-toolbar-title class="body-2">Stanje uporabnika</v-toolbar-title>
                                <v-spacer></v-spacer>
                                <v-btn icon @click="toggleCardContent('user-state')">
                                    <v-icon>{{ card3 ? 'mdi-chevron-down' : 'mdi-chevron-up' }}</v-icon>
                                </v-btn>

                            </v-app-bar>
                            <v-divider></v-divider>
                            <v-card-text v-show="card3">
                                <v-container grid-list-xl text-x-center align-center>
                                    <v-layout row justify-start v-bind="columnBinding">
                                        <v-flex xs12 align-content-end>
                                            <v-switch
                                                v-model="user_state"
                                                :label="`Uporabnik je na parkirišču: ${userStateParking}`"
                                                color="secondary"
                                                hide-details
                                            ></v-switch>
                                        </v-flex>
                                    </v-layout>
                                    <div style="min-height:7px;"></div>
                                </v-container>
                            </v-card-text>-->

                            <!-- datumska omejitev -->
                            <v-divider></v-divider>
                            <v-app-bar
                                flat
                                color="grey lighten-4"
                                dense
                                class="elevation-0"
                            >   
                                <v-icon left>mdi-file-document-outline</v-icon>
                                <v-toolbar-title class="body-2">Datumska omejitev</v-toolbar-title>
                                <v-spacer></v-spacer>
                                <v-btn icon @click="toggleCardContent('date-restrictions')">
                                    <v-icon>{{ card5 ? 'mdi-chevron-down' : 'mdi-chevron-up' }}</v-icon>
                                </v-btn>

                            </v-app-bar>
                            <v-divider></v-divider>
                            <v-card-text v-show="card5">
                                <v-container grid-list-xl text-x-center align-center>
                                    <v-layout row justify-center v-bind="columnBinding">
                                        <v-flex xs8 md4 align-content-center>
                                            <v-subheader class="pt-7 pl-0"><strong>Veljavnost dovolilnice</strong></v-subheader>
                                        </v-flex>
                                        <v-flex xs12 md4 align-content-center>
                                            <!-- začetni datum -->
                                            <v-text-field
                                                v-model="restriction_start_date_formatted"
                                                label="Datum od"
                                                append-icon="mdi-calendar-today"
                                                readonly
                                                filled
                                            ></v-text-field>
                                        </v-flex>
                                        <v-flex xs12 md4 align-content-center>
                                            <!-- končni datum -->
                                            <v-text-field
                                                v-model="restriction_end_date_formatted"
                                                label="Datum do"
                                                append-icon="mdi-calendar"
                                                readonly
                                                filled
                                            ></v-text-field>
                                        </v-flex>
                                    </v-layout>
                                </v-container> 
                            </v-card-text>
                            
                            <!-- omejitve -->
                            <!--<v-divider></v-divider>
                            <v-app-bar
                                flat
                                color="grey lighten-4"
                                dense
                                class="elevation-0"
                            >   
                                <v-icon left>mdi-cancel</v-icon>
                                <v-toolbar-title class="body-2">Tip omejitve</v-toolbar-title>
                                <v-spacer></v-spacer>
                                <v-btn icon @click="toggleCardContent('restrictions')">
                                    <v-icon>{{ card7 ? 'mdi-chevron-down' : 'mdi-chevron-up' }}</v-icon>
                                </v-btn>

                            </v-app-bar>
                            <v-divider></v-divider>
                            <v-card-text v-show="card7">
                                <v-container grid-list-xl text-x-center align-center>
                                    <v-layout row justify-center v-bind="columnBinding">
                                        <v-flex xs12 md4 align-content-center>
                                            <v-subheader class="pt-7 pl-0"><strong>Izberi tip omejitve</strong></v-subheader>
                                        </v-flex>
                                        <v-flex xs12 md8 align-content-center>
                                            <v-select
                                                :items="restrictionTypes"
                                                label="Tip omejitve"
                                                item-text="name"
                                                item-value="id"
                                                v-model="restriction_id"
                                                persistent-hint
                                            ></v-select>
                                        </v-flex>
                                    </v-layout>
                                </v-container>
                            </v-card-text>-->

                            <!-- casovne omejitve -->
                            <v-divider></v-divider>
                            <v-app-bar
                                flat
                                color="grey lighten-4"
                                dense
                                class="elevation-0"
                            >   
                                <v-icon left>mdi-file-document-outline</v-icon>
                                <v-toolbar-title class="body-2">Časovna omejitev</v-toolbar-title>
                                <v-spacer></v-spacer>
                                <v-btn icon @click="toggleCardContent('time-restrictions')">
                                    <v-icon>{{ card8 ? 'mdi-chevron-down' : 'mdi-chevron-up' }}</v-icon>
                                </v-btn>

                            </v-app-bar>
                            <v-divider></v-divider>
                            <v-card-text v-show="card8">
                                <v-container grid-list-xl text-x-center align-center>
                                    <v-layout row justify-center v-bind="columnBinding">
                                        <v-flex xs6 md4 align-content-center>
                                            <v-subheader class="pt-7 pl-0"><strong>Začetek časovne omejitve (izberi uro)</strong></v-subheader>
                                        </v-flex>
                                        <v-flex  xs12 md8 align-content-center>
                                            <v-text-field
                                                v-model="restriction_start_time"
                                                label="Začetni čas"
                                                append-icon="mdi-clock-outline"
                                                readonly
                                                filled
                                            ></v-text-field>
                                        </v-flex>
                                    </v-layout>
                                     <v-layout row justify-center v-bind="columnBinding">
                                        <v-flex xs12 md4 align-content-center>
                                            <v-subheader class="pt-7 pl-0"><strong>Konec časovne omejitve (izberi uro)</strong></v-subheader>
                                        </v-flex>
                                        <v-flex xs12 md8 align-content-center>
                                            <v-text-field
                                                v-model="restriction_end_time"
                                                label="Končni čas"
                                                append-icon="mdi-clock-outline"
                                                readonly
                                                filled
                                            ></v-text-field>
                                        </v-flex>
                                    </v-layout>
                                    <!--<v-layout row justify-center v-bind="columnBinding">
                                        <v-flex xs6 md4 align-content-center>
                                            <v-subheader class="pt-7 pl-0"><strong>Časovna omejitev zadrževanja (minute)</strong></v-subheader>
                                        </v-flex>
                                        <v-flex xs12 md8 align-content-center>
                                            <v-text-field
                                                v-model="restriction_time_period"
                                                label="Čas v minutah"
                                                required
                                                append-icon="mdi-timer-sand"
                                                type="number"
                                                filled
                                            ></v-text-field>
                                        </v-flex>
                                    </v-layout>-->

                                    <v-layout row justify-center v-bind="columnBinding">
                                        <v-flex xs12 md4 align-content-left v-if="$vuetify.breakpoint.lgAndUp">
                                            <v-subheader><strong></strong></v-subheader>
                                        </v-flex>
                                        <v-flex xs12 md8 align-content-left>
                                            <p class="body-1">Upoštevaj časovno omejitev: {{ useTimeRestriction }}</p>
                                        </v-flex>
                                    </v-layout>
                                </v-container> 
                            </v-card-text>

                            <v-divider></v-divider>
                            <v-card-text v-if="$vuetify.breakpoint.xsOnly">
                                <v-btn block outlined style="margin-bottom:10px;" @click="submitForm()" color="success" :disabled="loading"><v-icon left>mdi-arrow-right</v-icon>{{ btnSubmitFormTitle }}</v-btn>
                                <v-btn block outlined style="" @click="clearForm()" color="warning"><v-icon left>mdi-refresh</v-icon>Prekliči</v-btn>
                            </v-card-text>
                            <v-card-text v-else>
                                <v-btn @click="submitForm()" color="success" text :disabled="loading"><v-icon left>mdi-arrow-right</v-icon>{{ btnSubmitFormTitle }}</v-btn>
                                <v-btn @click="clearForm()" color="warning" text><v-icon left>mdi-refresh</v-icon>Prekliči</v-btn>
                            </v-card-text>
                        </v-card>
                    </v-flex>
                </v-layout>
            </v-form>
        </v-container>
    </div>
</template>

<script>
import { formatDate, randomPinNumber } from '@/helpers/utilities.js'
const FormValidationMessages = () => import('@/components/FormValidationMessages.vue')
const FormSubmitMessages = () => import('@/components/FormSubmitMessages.vue')

export default {

    components: {
        FormValidationMessages,
        FormSubmitMessages
    },

    data: () => ({
        valid: true,
        form: true,
        card1: true,
        card2: true,
        card3: true,
        card4: true,
        card5: true,
        card6: true,
        card7: true,
        card8: true,
        card9: true,

        loading: false,
        scanRfidLoading: false,
        storeUserLoading: false,
        restriction_start_date_formatted: "15.04.2020",
        restriction_end_date_formatted: "31.05.2020",
        start_date_menu: false,
        end_date_menu:false,
        start_time_menu:false,
        end_time_menu:false,

        user_id: null,
        user_uuid: null,

        name: null,
        address: null,
        city: null,
        phone_number: null,
        email: null,
        phone_number_1: null,
        phone_number_2: null,
        phone_number_3: null,
        phone_number_4: null,
        phone_number_5: null,
        phone_numbers_limit: 1,
        phone_numbers_enabled: true,
        registration_number_1: null,
        registration_number_2: null,
        registration_number_3: null,
        registration_number_4: null,
        registration_number_5: null,
        registration_numbers_limit: 1,
        registration_numbers_enabled: true,
        restriction_start_date: null,
        restriction_end_date: null,
        restriction_start_time: null,
        restriction_end_time: null,
        use_time_restriction: false,
        restriction_time_period: null,
        card_id: null,
        card_number: null,
        card_scan_input: null,
        card_blocked: true,
        card_number_input_enabled: false,
        card_id_input_enabled: false,
        restriction_id: null,
        user_state: false,

         card_id_rules: [
            v => (!!v) || 'Vnos ID kartice je obvezen!',
        ],

        restrictionTypes: [],
        backendMessages: [],
        backendErrorMessages: [],
        backendValidationMessages:[],

        name_rules: [v => !!v || 'Polje ime in priimek je obvezno'],
        //email_rules: [v => !!v || 'Polje e-mail je obvezno'],


    }),

    computed: {
        columnBinding() {
            const binding = {}

            if (this.$vuetify.breakpoint.mdAndDown) binding.column = true

            return binding
        },

        btnSubmitFormTitle() {
            if(this.$vuetify.breakpoint.smAndDown) {
                return 'Shrani'
            }

            return 'Shrani'
        },

        userStateParking() {
            if(this.user_state) {
                return 'DA'
            }

            return 'NE'
        },

        userPhoneNumbersEnabled() {
            if(this.phone_numbers_enabled) {
                return 'DA'
            }

            return 'NE'
        },

        cardBlocked() {
            if(this.card_blocked) {
                return 'DA'
            }

            return 'NE'
        },

        userRegistrationNumbersEnabled() {
            if(this.registration_numbers_enabled) {
                return 'DA'
            }

            return 'NE'
        },

        useTimeRestriction() {
            if(this.use_time_restriction) {
                return 'DA'
            }

            return 'NE'
        },

        getCardNumber() {
           if(this.card_number == null || this.card_number == '') return false

           return true
        },

        apiMessages() {
            return this.backendMessages;
        },

        apiErrorMessages() {
            return this.backendErrorMessages 
        },

        apiValidationMessages() {
            return this.backendValidationMessages
        },

        remoteControllerBlocked() {
            if(this.remote_control_blocked) {
                return 'DA'
            }

            return 'NE'
        },

        phoneNumbersColSizes() {
            return (this.phone_numbers_limit > 1) ? "6" : "12"
        },

        registrationNumbersColSizes() {
            return (this.registration_numbers_limit > 1) ? "6" : "12"
        }
    },

    watch: {
        restriction_start_date (val) {
            this.restriction_start_date_formatted = formatDate(val)
        },

        restriction_end_date (val) {
            this.restriction_end_date_formatted = formatDate(val)
        },

        registration_number_1(value) {
            if(value != null) {
                //window.console.log(this.registration_number_1)
                this.registration_number_1 = this.registration_number_1.replace(/[^a-zA-Z0-9]/g, "");
                this.registration_number_1 = this.registration_number_1.toUpperCase();
            }
        },

        registration_number_2(value) {
            if(value != null) {
                //window.console.log(this.registration_number_1)
                this.registration_number_2 = this.registration_number_2.replace(/[^a-zA-Z0-9]/g, "");
                this.registration_number_2 = this.registration_number_2.toUpperCase();
            }
        },

        registration_number_3(value) {
            if(value != null) {
                //window.console.log(this.registration_number_3)
                this.registration_number_3 = this.registration_number_3.replace(/[^a-zA-Z0-9]/g, "");
                this.registration_number_3 = this.registration_number_3.toUpperCase();
            }
        },

        registration_number_4(value) {
            if(value != null) {
                //window.console.log(this.registration_number_1)
                this.registration_number_4 = this.registration_number_4.replace(/[^a-zA-Z0-9]/g, "");
                this.registration_number_4 = this.registration_number_4.toUpperCase();
            }
        },

        registration_number_5(value) {
            if(value != null) {
                //window.console.log(this.registration_number_1)
                this.registration_number_5 = this.registration_number_5.replace(/[^a-zA-Z0-9]/g, "");
                this.registration_number_5 = this.registration_number_5.toUpperCase();
            }
        }
    },

    methods: {
        toggleCardContent(card) {
            if(card === 'user-data') {
                this.card1 = !this.card1
            }

            if(card === 'phone-numbers') {
                this.card2 = !this.card2
            }

            if(card === 'user-state') {
                this.card3 = !this.card3
            }

            if(card === 'pin') {
                this.card4 = !this.card4
            }

            if(card === 'date-restrictions') {
                this.card5 = !this.card5
            }

            if(card === 'rfid-card') {
                this.card6 = !this.card6
            }
            
            if(card === 'restrictions') {
                this.card7 = !this.card7
            }

            if(card === 'time-restrictions') {
                this.card8 = !this.card8
            }

            if(card === 'registration-numbers') {
                this.card9 = !this.card9
            }
        },

        startDateMenuSave(value) {
            this.$refs.start_date_menu.save(value)
            this.start_date_menu = false
        },

        endDateMenuSave(value) {
            this.$refs.end_date_menu.save(value)
            this.end_date_menu = false
        },

        RfidNr() {
            let data = this.card_number
            this.card_scan_input = this.card_number
            var vm = this
            vm.$store.commit('SET_PROGRESS', true)
            this.scanRfidLoading = true
            this.$store.dispatch('CONVERT_RFID_NR', {nr: data})
            .then(response => {
                //window.console.log(response.data)
                //window.console.log(response.rfidNr)
                this.card_number = response.data.rfidNr
                this.scanRfidLoading = false
                vm.$store.commit('SET_SNACKBAR_DISPLAY', false)
                vm.$store.commit('SET_SNACKBAR_TEXT', "Kartica uspešno poskenirana. Serijska številka kartice: " +  response.data.rfidNr + ". Vpišite še ID kartice.")
                vm.$store.commit('SET_SNACKBAR_MODE', 'multi-line')
                vm.$store.commit('SET_SNACKBAR_TIMEOUT', 7000)
                vm.$store.commit('SET_SNACKBAR_DISPLAY', true)

                this.card_id_input_enabled = true
                setTimeout(() => {
                        vm.$refs.cardId.focus()
                    }, 350)

            })
            .catch(error => {
                window.console.log('RfidNr error')
                window.console.log(error)
                this.scanRfidLoading = false    
                this.removeRfidCard()
                vm.$store.commit('SET_SNACKBAR_DISPLAY', false)
                vm.$store.commit('SET_SNACKBAR_TEXT', 'Pri skeniranju kartice je prišlo do napake. Poskusite ponovno.')
                vm.$store.commit('SET_SNACKBAR_MODE', '')
                vm.$store.commit('SET_SNACKBAR_TIMEOUT', 7000)
                vm.$store.commit('SET_SNACKBAR_DISPLAY', true)

            })
            .finally(() => {
                vm.$store.commit('SET_PROGRESS', false)
            })

            this.cardNumberInputEnabled = false
        },

        removeRfidCard() {
            this.card_id = null;
            this.card_number = null;
            this.card_scan_input = null;
            this.card_blocked = null;

            this.card_id_input_enabled = false;
            this.card_number_input_enabled = false;
            //this.$refs.form.validate();
            this.$refs.cardId.blur();
            this.$refs.cardNumber.blur();

        },

        enableRfidCardInput() {
            this.removeRfidCard()
            this.card_number_input_enabled = true

            setTimeout(() => {
                this.$refs.cardNumber.focus()
            }, 350)
        },

        submitForm() {
            if(this.$refs.form.validate()) {
                this.backendValidationMessages = []
                this.backendMessages = []
                this.backendErrorMessages = []

                var vm = this
                vm.$store.commit('SET_PROGRESS', true)
                vm.$store.commit('SET_LOADER_TEXT', 'Posodobitev podatkov je v teku...')
                vm.$store.commit('SET_LOADER', true)
                vm.storeUserLoading = true
                vm.$store.dispatch('UPDATE_USER_DATA', {
                    user_id: vm.user_id,
                    user_uuid: vm.user_uuid,
                    phone_number_1: vm.phone_number_1,
                    phone_number_2: vm.phone_number_2,
                    phone_number_3: vm.phone_number_3,
                    phone_number_4: vm.phone_number_4,
                    phone_number_5: vm.phone_number_5,
                   
                    registration_number_1: vm.registration_number_1,
                    registration_number_2: vm.registration_number_2,
                    registration_number_3: vm.registration_number_3,
                    registration_number_4: vm.registration_number_4,
                    registration_number_5: vm.registration_number_5
                    //user_state: vm.user_state
                    
                })
                .then(response => {
                    //window.console.log(response)
                    let user = vm.name
                    setTimeout(() =>{
                        vm.$store.commit('snackbarDisplay', false)
                        vm.$store.commit('snackbarText', "Podatki so bili uspešno posodobljeni.")
                        vm.$store.commit('snackbarMode', '')
                        vm.$store.commit('snackbarTimeout', 7000)
                        vm.$store.commit('snackbarDisplay', true)
                    }, 600)

                    /*vm.backendMessages.push("Uporabnik " + user + " je bil uspešno vnesen v sistem.")
                        vm.$vuetify.goTo('#form_card', {
                        duration: 500,
                        offset: 0,
                        easing: 'easeInOutCubic'
                    })*/
                })
                .catch(error => {
                    console.log('UPDATE USER DATA error')
                    console.log(error)
                    setTimeout(() =>{
                        vm.$store.commit('snackbarDisplay', false)
                        vm.$store.commit('snackbarMode', 'multi-line')
                        vm.$store.commit('snackbarText', 'Pri posodobitvi podatkov je prišlo do napake. Prosimo poskusite ponovno.')
                        vm.$store.commit('snackbarDisplay', true)
                    }, 600)

                    if(error.response.status === 422) {
                        window.console.log(error.response.data.errors)
                        for (const property in error.response.data.errors) {
                            //window.console.log(`${property}: ${error.response.data.errors[property]}`);
                            var propertyData = error.response.data.errors[property];
                            //window.console.log(propertyData);
                            propertyData.forEach(message => {
                                vm.backendValidationMessages.push(message);
                            })

                            vm.$vuetify.goTo('#form_card', {
                                duration: 500,
                                offset: 0,
                                easing: 'easeInOutCubic'
                            })
                        }
                    } else {
                        vm.backendErrorMessages.push('Ups... pri posodobitvi podatkov prišlo do napake. Poskusite ponovno.')
                        vm.$vuetify.goTo('#form_card', {
                            duration: 1000,
                            offset: 0,
                            easing: 'easeInOutCubic'
                        })
                    }

                })
                .finally(() => {
                    setTimeout(() =>{
                        vm.storeUserLoading = false;
                        vm.$store.commit('SET_PROGRESS', false)
                        vm.$store.commit('SET_LOADER', false)
                        vm.$store.commit('SET_LOADER_TEXT', '')
                    },700)
                })

            }
            else
            {
                this.$vuetify.goTo('#form_card', {
                    duration: 500,
                    offset: 0,
                    easing: 'easeInOutCubic'
                })
            } 
        },

        clearBackendMessages() {
            this.backendValidationMessages = []
            this.backendMessages = []
            this.backendErrorMessages = []
        },

        clearForm() {
            this.$refs.form.resetValidation();
            this.clearBackendMessages()
            this.getUserData()
        },

        setDefaultFormValues() {
            this.name = null
            this.address = null
            this.city = null
            this.phone_number = null
            this.email = null
            this.phone_number_1 = null
            this.phone_number_2 = null
            this.phone_number_3 = null
            this.phone_number_4 = null
            this.phone_number_5 = null
            this.phone_numbers_limit = 1
            this.phone_numbers_enabled = null
            this.registration_number_1 = null
            this.registration_number_2 = null
            this.registration_number_3 = null
            this.registration_number_4 = null
            this.registration_number_5 = null
            this.registration_numbers_limit = 1
            this.registration_numbers_enabled = null
            this.restriction_start_date = null
            this.restriction_end_date = null
            this.restriction_start_time = null
            this.restriction_end_time = null
            this.use_time_restriction = false
            this.restriction_time_period = null
            this.card_id = null
            this.card_number = null
            this.card_scan_input = null
            this.card_blocked = false
            this.restriction_id = null
            this.user_state = false
        },

        getUserData() {
            var vm = this
            vm.$store.commit('SET_PROGRESS', true)
            vm.$store.commit('SET_LOADER_TEXT', 'Pridobivanje podatkov...')
            vm.$store.commit('SET_LOADER', true)
            this.$store.dispatch('USER_DATA', this.$route.params.id)
            .then(response => {
                //window.console.log(response.data)
                vm.rifd_user_data = response.data
                vm.user_id = response.data.id
                vm.user_uuid = response.data.uuid

                vm.name = response.data.name
                vm.address = response.data.address
                vm.city = response.data.city
                vm.email = response.data.email
                vm.phone_number_1 = response.data.phone_number_1
                vm.phone_number_2 = response.data.phone_number_2
                vm.phone_number_3 = response.data.phone_number_3
                vm.phone_number_4 = response.data.phone_number_4
                vm.phone_number_5 = response.data.phone_number_5
                vm.phone_numbers_limit = response.data.phone_numbers_limit;
                vm.phone_numbers_enabled = (response.data.phone_numbers_enabled == 1 ) ? true : false,
                vm.registration_number_1 = response.data.registration_number_1;
                vm.registration_number_2 = response.data.registration_number_2;
                vm.registration_number_3 = response.data.registration_number_3;
                vm.registration_number_4 = response.data.registration_number_4;
                vm.registration_number_5 = response.data.registration_number_5;
                vm.registration_numbers_limit = response.data.registration_numbers_limit;
                vm.registration_numbers_enabled = (response.data.registration_numbers_enabled == 1) ? true : false,
                vm.card_id = response.data.card_id
                vm.card_number = response.data.card_number
                vm.card_scan_input = response.data.card_scan_input
                
                if(response.data.card_blocked == 1) {
                    vm.card_blocked = true
                } else {
                    vm.card_blocked = false
                }

                vm.restriction_start_date = response.data.restriction_start_date
                vm.restriction_end_date = response.data.restriction_end_date

                if(vm.restriction_start_date != null && vm.restriction_start_date != undefined) {
                    this.restriction_start_date_formatted = formatDate(vm.restriction_start_date)
                }

                if(vm.restriction_end_date != null && vm.restriction_end_date != undefined) {
                    this.restriction_end_date_formatted = formatDate(vm.restriction_end_date)
                }

                vm.restriction_id = response.data.restriction_id

                vm.restriction_start_time = response.data.restriction_start_time
                vm.restriction_end_time = response.data.restriction_end_time
            
                if(response.data.use_time_restriction == 1) {
                    vm.use_time_restriction = true
                } else {
                    vm.use_time_restriction = false
                }

                vm.restriction_time_period = response.data.restriction_time_period
                
                
            })
            .catch(error => {
                window.console.error('### ERROR EditUser@getUserData ###')
                window.console.error(error)
                vm.$store.commit('snackbarDisplay', false)
                vm.$store.commit('snackbarMode', 'multi-line')
                vm.$store.commit('snackbarText', 'Pri pridobivanju podatkov o uporabniku je prišlo do napake.')
                vm.$store.commit('snackbarDisplay', true)
            })
            .finally(() =>{
                setTimeout(() => {
                    vm.$storeUserLoading = false
                    vm.$store.commit('SET_PROGRESS', false)
                    vm.$store.commit('SET_LOADER', false)
                    vm.$store.commit('SET_LOADER_TEXT', '')
                }, 700)
            })
        }
    },

    created() {
        this.$store.dispatch('ROUTE_NAME', this.$route.name) 
    },

    mounted() {
        this.getUserData();
    },

    destroyed() {

    }
}

</script>

<style scoped>
    .btn_rfid {
        margin-right:10px;
        margin-bottom: 15px;
    }

    .card--text {
        color: rgb(0,0,0,0.8) !important;
    }
</style>